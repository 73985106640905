import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { completeTaskLocal, pauseTask, playTask, setAddNodeModalOpen, setCurrentTask, setIsCurrentTaskRunning, setManuallyScheduledNodeId, setParentIdOfNodeTobeCreated, setParentTaskCompleteAlertModalOpen, setStartTime } from "../../../store/actions";
import { DiferDurations } from "./DiferDurations";
import { Icons } from "../../../components/Icons";
import moment from "moment";
import { confettiRise } from "../../../assets/sounds";
import { toast } from "react-toastify";
import { SET_IS_PAUSED, UPDATE_NODE } from "../../../constants";
import { Spinner } from "./Spinner";
import { RoundButton } from "../../../components";
import { getTreeData } from "../../../pages/ICICLETree/Index";

interface ActionButtonsProps {
}

const ActionButtons: React.FC<ActionButtonsProps> = () => {
    const [isDifferMenuOpen, setIsDifferMenuOpen] = useState(false);
    const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
    const dispatch:any = useDispatch();
    const treeData = useSelector((state: RootState) => state.tree);
    const [immediateTask, setImmediateTask] = useState<string | null>(null);
    const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
    const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
    const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
    const [isCompleteLoading, setIsCompleteLoading] = useState(false);
    

    const handlePlayTaskBtnClick = () => {
        if (!currentTask) return;
        
        const startTime = new Date();
        dispatch(setIsCurrentTaskRunning(true));
        dispatch(setStartTime(startTime));

        const funcs = {
            preRun: () => {
                setIsPlayPauseLoading(true);
            },
            runIfSuccess: () => {
                setIsPlayPauseLoading(false);
            },
            runIfFails: () => {
                setIsPlayPauseLoading(false);
                dispatch(setIsCurrentTaskRunning(false));
                dispatch(setStartTime(null));
            }
        }

        dispatch(playTask(currentTask._id, startTime.toString(),funcs));
    }

    const handlePauseTaskBtnClick = async () => {
            if (!currentTask) return;

            const funcs = {
                preRun: () => {
                    setIsPlayPauseLoading(true);
                },
                runIfSuccess: () => {
                    setIsPlayPauseLoading(false);
                },
                runIfFails: () => {
                    setIsPlayPauseLoading(false);
                }
            }

            const endTime = moment().valueOf();
            dispatch(setIsCurrentTaskRunning(false));
            dispatch(setStartTime(null));
            dispatch(pauseTask(currentTask._id, endTime.toString(), false,funcs));

            // const res = await createExploitationDuration({currentTask: currentTask});
    }

    const handleCompletedBtnClick = async () => {
		if (!currentTask) return;
        const treeData = getTreeData();
      
        function ifParentTaskHasOneChild(){
            const children = treeData.descendants().find((n:any) => n.data._id === currentTask.parent).children.filter((c:any) => !c.data.isCompleted && !c.data.isTempCompleted);
            if (children?.length === 1) {
                dispatch(setParentTaskCompleteAlertModalOpen(true));  
            }
        }

        const endTime = new Date().getTime() ;
        setIsCompleteLoading(true);

        const res:any = await completeTaskLocal(currentTask._id,currentTask.nodeProductivityValue,endTime.toString());

        setIsCompleteLoading(false);
    
        if(res.success){
            confettiRise.volume = .1;
            confettiRise.play();
            toast.success(res.message);
            ifParentTaskHasOneChild();
            dispatch({ type: SET_IS_PAUSED, payload: false });
            dispatch({ type: UPDATE_NODE, payload: {...res.node,startTime: null} });
            const remainingIds = manuallyScheduleNodeId?.filter((_id:any) => _id !== res.node._id);
            dispatch(setManuallyScheduledNodeId(remainingIds));
            dispatch(setParentIdOfNodeTobeCreated(currentTask.parent));
            dispatch(setCurrentTask(null));
        }
		// const parent = currentTasksParents.find((t:any) => t._id === currentTask.parent);

		// if (parent?.children?.length === 1) {
		// 	dispatch(setParentTaskCompleteAlertModalOpen(true));

		// 	if (isCurrentTaskRunning) {
		// 		dispatch(setIsCurrentTaskRunning(false))
		// 	}
		// 	return;
		// }

		// if (currentTask.isSomeOneWorkingOnThis) {
		// 	const endTime = moment().valueOf();
		// 	dispatch(completeTask(currentTask._id,currentTask.nodeProductivityValue, endTime.toString()));
        //     dispatch(setIsCurrentTaskRunning(false));
		// 	dispatch(setStartTime(null));
		// } 
		// else {
		// 	dispatch(completeTask(currentTask._id,currentTask.nodeProductivityValue));
		// }

		// if (manuallyScheduleNodeId && manuallyScheduleNodeId[manuallyScheduleNodeId.length - 1] === currentTask._id) {
		// 	const remainingIds = manuallyScheduleNodeId.filter((id:string) => id !== currentTask._id);
		// 	dispatch(setManuallyScheduledNodeId(remainingIds.length ? remainingIds : null));
		// }
	};

    const loadImmediateTask = () => {
        treeData?.children.forEach(child => {
            if (child.isImmediateTask) {
                setImmediateTask(child._id);
                return;
            }
            child.children.forEach(child1 => {
                if (child1.isImmediateTask) {
                    setImmediateTask(child1._id);
                    return;
                }
            });
        });
    };

    const toggleIsDifferMenuOpen = () => {
        if (!currentTask) {
            return;
        }
        setIsDifferMenuOpen(prev => !prev);
    };

    const toggleIsAddMenuOpen = () => {
        if (!currentTask) {
            loadImmediateTask();
            dispatch(setAddNodeModalOpen(true));
            return;
        }
        setIsAddMenuOpen(prev => !prev);
    };

    const handleAddNodeToCurrentTask = (e: React.MouseEvent<HTMLParagraphElement>) => {
        if (e.currentTarget.id === "to-current") {
            dispatch(setAddNodeModalOpen(true));
            dispatch(setParentIdOfNodeTobeCreated(currentTask._id));
        } else {
            loadImmediateTask();
            dispatch(setAddNodeModalOpen(true));
        }
        setIsAddMenuOpen(prev => !prev);
    };

    const MyButton: React.FC<{ onClick: () => void, children: any,style?:any }> = ({ children, onClick,style }) => (
        <div
            style={style}
            onClick={onClick}
            className="w-[45px] md:w-[30px] h-[45px] md:h-[30px]"
        >
            <RoundButton style={{width: "100%",height: "100%",padding: style?.padding === 0? 0:""}}>{children}</RoundButton>
        </div>
    );

    useEffect(() => {
        if (immediateTask) {
            dispatch(setParentIdOfNodeTobeCreated(immediateTask));
            dispatch(setManuallyScheduledNodeId([immediateTask]));
        }
    }, [immediateTask, dispatch]);

    
    

    return (
        <>
            {/* play pause buttons  */}
            {
                (currentTask?.startTime && !isPlayPauseLoading) && <MyButton onClick={handlePauseTaskBtnClick}><Icons.PauseWhite/></MyButton>
            }
            {
                (!currentTask?.startTime && !isPlayPauseLoading) && <MyButton onClick={handlePlayTaskBtnClick}><Icons.PlayWhite/></MyButton>
            }
            {
                isPlayPauseLoading && <MyButton style={{padding: 0}} onClick={()=>{}}><Spinner/></MyButton>
            }
            
            
            {/* share button  */}
            <MyButton onClick={()=>{}}><Icons.SahareWhite/></MyButton>


            {/* defer button  */}
            <div className="relative">
                <MyButton onClick={toggleIsDifferMenuOpen}>
                    <Icons.NextDoubleWhite/>
                </MyButton>
                {isDifferMenuOpen && (
                    <DiferDurations 
                        toggleIsDifferMenuOpen={() => setIsDifferMenuOpen(false)} 
                        id={currentTask?._id}
                    />
                )}
            </div>


            {/* add nde button  */}
            <div className="relative">
                            <MyButton onClick={toggleIsAddMenuOpen}>
                                <Icons.PlausWhite/>
                            </MyButton>
                            {isAddMenuOpen && (
                                <div className={`absolute top-[140%] -left-[70px] w-[150px] bg-white rounded-md shadow-md p-2`}>
                                    <p onClick={handleAddNodeToCurrentTask} id='to-current' className='mb-0 cursor-pointer px-2 rounded-md hover:bg-gray-300'>Current Task</p>
                                    <p onClick={handleAddNodeToCurrentTask} className='mb-0 cursor-pointer px-2 rounded-md hover:bg-gray-300'>Immediate Task</p>
                                </div>
                            )}
            </div>


            {/* complete task button  */}
            {
                isCompleteLoading? <MyButton style={{padding: 0}} onClick={()=>{}}><Spinner/></MyButton>:<MyButton onClick={handleCompletedBtnClick}><Icons.CheckWhite/></MyButton>
            }
            
        </>
    );
};


export {ActionButtons};





