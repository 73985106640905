import { ProgressBar } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getRunningTask, getTaskById, setTimingAlertModalOpen } from "../../../store/actions";
import { totalExploitationDuration } from "../../../utils/initialCalculations";

interface IRemaningTime {
}

const RemaningTime: React.FC<IRemaningTime> = () => {
	const ct = useSelector((state: RootState) => state.globalStates.currentTask);
	const [currentTask, setSurrentTask] = useState<any>(null);
	const [remainingTime, setRemaningTime] = useState(0);
	useTA(currentTask);
	const taskCompletedInPer = 100;
	
	// console.log(ct?.nodeProductivityValue);
	
	useEffect(() => {
		if(!currentTask?.extraTaskDuration){
			return;
		}

		const interval = setInterval(() => {
			let estimatedTaskDuration = currentTask?.estimatedTaskDuration

			if(currentTask?.extraTaskDuration && currentTask.extraTaskDuration.duration && moment(currentTask.extraTaskDuration.date).startOf("day").isSame(moment().startOf("day"))){
			    estimatedTaskDuration = currentTask.estimatedTaskDuration+currentTask.extraTaskDuration.duration;
			}

			let remTime:any = undefined;
			if (!currentTask?.startTime) {
				remTime = (estimatedTaskDuration - currentTask?.taskExploitationDuration);
				clearInterval(interval);
			} 
			else {
				const currMinutes = moment().diff(moment(currentTask?.startTime), "seconds") / 60;
				remTime = ((estimatedTaskDuration - currentTask?.taskExploitationDuration) - currMinutes);
			}
			setRemaningTime(remTime);
		}, 1000);

		return () => {
			return clearInterval(interval);
		};
	}, [currentTask?.extraTaskDuration,currentTask?.estimatedTaskDuration,currentTask?.startTime,currentTask?.taskExploitationDuration]);
	
	useEffect(() => {
		(async () => {
			if(ct){
				const res = await getRunningTask();
				const res1:any = await getTaskById(ct._id);
	
				// console.log(res);
				if(res?.success && res.node){
					const node:any = res.node;
					const isRecurring = Boolean(node.recurring?.frequency);
					node.taskExploitationDuration = totalExploitationDuration(node.taskExploitationDuration, isRecurring);
					setSurrentTask(node);
				}
				if(res1?.success && res1.node && res1.exploitationDurations){
					const node:any = res1.node;
					const isRecurring = Boolean(node.recurring?.frequency);
					node.taskExploitationDuration = totalExploitationDuration(res1.exploitationDurations, isRecurring);
					setSurrentTask(node);
				}
			}
			else {
				setSurrentTask(null);
			}
		})()
	}, [ct]);

	return <>
	{currentTask && (
				<>
					<p className='mb-1'>Remaining Time</p>
					<div className="h-4">
						<ProgressBar text={`${parseFloat(remainingTime.toString()).toFixed(2)} mins`} value={Number(remainingTime) < 0 ? 0 : taskCompletedInPer} />
					</div>
				</>
			)}
	</>
}

export {RemaningTime};



function useTA(currentTask:any){
	const dispatch:any = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			let remTime:any = undefined;

			let estimatedTaskDuration = currentTask?.estimatedTaskDuration

			if(currentTask?.extraTaskDuration && currentTask.extraTaskDuration.duration && moment(currentTask.extraTaskDuration.date).startOf("day").isSame(moment().startOf("day"))){
			    estimatedTaskDuration = currentTask.estimatedTaskDuration+currentTask.extraTaskDuration.duration;
			}

			if (!currentTask?.startTime) {
				remTime = (estimatedTaskDuration - currentTask?.taskExploitationDuration);
			} 
			else {
				const currMinutes = moment().diff(moment(currentTask?.startTime), "seconds") / 60;
				remTime = ((estimatedTaskDuration - currentTask?.taskExploitationDuration) - currMinutes);
			}

			if (Number(remTime) < 0 && currentTask?.startTime) {
				dispatch(setTimingAlertModalOpen(true));
				clearInterval(interval);
			} 
			else {
				dispatch(setTimingAlertModalOpen(false));
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		}
	}, [currentTask,dispatch]);
}