import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsFullViewOn, setNavigatePointerNodeId, toggleIsCompletedNodesVisible } from "../../../store/actions";
import { getBreadCrumbsOfLoggedInUser } from "../../../store/actions/exploitationDuration";




const BreadCrumbs: React.FC = () => {
	const isCompleted = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
	const [exploitationDurations, setExploitationDurations] = useState<any[] | null>(null);
	const dispatch:any = useDispatch();
	const tree = useSelector((state: RootState) => state.tree);
	// const [showTooltip, setShowTooltip] = useState(false);
	

	const handleClick = (breadCrumb: any) => {
		const nodeId = breadCrumb.nodesTillLifeDivisions.length === 0? "":breadCrumb.nodesTillLifeDivisions[breadCrumb.nodesTillLifeDivisions.length-1]._id;

		if(nodeId){
			dispatch(setIsFullViewOn(false));
			if (!isCompleted) {
				dispatch(toggleIsCompletedNodesVisible());
			}
			dispatch(setNavigatePointerNodeId(nodeId));
		}
	};

	useEffect(() => {
		(async () => {
			const res = await getBreadCrumbsOfLoggedInUser();
			console.log(res);
			if(res?.success){
				setExploitationDurations(res.exploitationDurations)
			}
		})()
	}, [tree]);

	return (
		<div className="flex justify-start items-center gap-2 pt-2 flex-wrap">
			{exploitationDurations && exploitationDurations.map((breadCrumb, idx) => {
				
				return <div 
							onClick={() => handleClick(breadCrumb)} 
							key={idx} 
							title={`${breadCrumb.nodesTillLifeDivisions.length === 0? "Unknown":breadCrumb.nodesTillLifeDivisions[breadCrumb.nodesTillLifeDivisions.length-1].objective}`} 
							style={{ backgroundColor: breadCrumb.color || "green" }} 
							className="relative cursor-pointer rounded-full w-8 md:w-4 h-8 md:h-4 text-[16px] md:text-[10px] text-white flex justify-center items-center"
						>
							{exploitationDurations.length - idx}
							{/* <span className="">asdg</span> */}
						</div>
            })}
		</div>
	);
};


export {BreadCrumbs}