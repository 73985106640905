import React, { useRef } from 'react';
import FormField from "../FormField/Index";
import { setUpdateNodeModalOpen } from "../../store/actions/globalStates";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RecurringForm from '../RecurringForm';
import { createPlanningDuration } from '../../store/actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface FormProps {
  e: any
  timingData: any; // Replace with the actual type if known
  nodeToBeEdited: any; // Replace with the actual type if known
  setTimingData: React.Dispatch<React.SetStateAction<any>>; // Replace with the actual type if known
}

const Form: React.FC<FormProps> = ({ e, timingData, setTimingData,nodeToBeEdited }) => {
  const navigator = useNavigate();
  const dispatch:any = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const updateNodeBtnClickTime:any = useSelector((state: RootState) => state.globalStates.updateNodeBtnClickTime);


  const handleSetObjective = () => {
    dispatch(setUpdateNodeModalOpen(false));
    navigator("/set-objective", {
      state: {
        _id: e.values._id,
        objective: e.values.objective,
        objectiveArr: e.values.objectiveArr,
      },
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === "Enter") {
      submitBtnRef.current?.click();
    }
  };

  return (
    <>
      <button onClick={handleSetObjective} className='text-sm bg-blue-600 text-white px-3 py-1 shadow-sm rounded-md'>Set Objective</button>
      <form tabIndex={0} onKeyDown={handleKeyDown} className="w-full">
        {e.values.depth === 2 && <FormField name="role" value={e.values.role} handleChange={e.handleChange} label="Role" type="text" />}
        <FormField name="objective" isHeigLighted={true} value={e.values.objective} handleChange={e.handleChange} label="Objective" type="text" />
        <FormField name="result" value={e.values.result} handleChange={e.handleChange} label="Key Results" type="text" />
        <FormField name="body" value={e.values.body} handleChange={e.handleChange} label="Notes" type="text" />
        <FormField name="deadline" value={e.values.deadline || ""} handleChange={e.handleChange} label="Deadline" type="date" />
        <FormField name="legtime" value={e.values.legtime} handleChange={e.handleChange} label="Lag Time" type="text" />
        <FormField name="leadtime" value={e.values.leadtime} handleChange={e.handleChange} label="Lead Time" type="text" />
        <FormField name="taskOrder" value={e.values.taskOrder} handleChange={e.handleChange} label="Task Order" type="number" />
        <FormField name="estimatedTaskDuration" value={e.values.estimatedTaskDuration === 0 ? 1 : e.values.estimatedTaskDuration} handleChange={e.handleChange} label="Est. Duration" type="number" />
        <FormField name="color" value={e.values.color || "#000000"} handleChange={e.handleChange} label="Color" type="color" />
        
        {["isCompleted", "isDeleted", "isCapturePark", "isImmediateTask"].map((field) => (
          <div className="flex items-center mb-6" key={field}>
            <input
              checked={e.values[field as keyof typeof e.values]}
              id={field}
              type="checkbox"
              onChange={e.handleChange}
              value={e.values[field as keyof typeof e.values]}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor={field} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{field.replace(/is/, "").replace(/([A-Z])/g, ' $1')}</label>
          </div>
        ))}

        <h6 className='p-2 flex items-center justify-center gap-2'>Recurring Details 
          <input type="checkbox" checked={e.values.isRecurringTask} onChange={e.handleChange} name='isRecurringTask' id='isRecurringTask' />
        </h6>
        {e.values.isRecurringTask && <RecurringForm timingData={timingData} setTimingData={setTimingData} />}
        
        <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button 
          onClick={async (e) => {
            e.preventDefault(); 
            dispatch(setUpdateNodeModalOpen(false));
            await createPlanningDuration({startTime: updateNodeBtnClickTime,endTime: new Date(),node: nodeToBeEdited?._id}); 
          }} 
          type="button" 
          className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
          <button ref={submitBtnRef} onClick={e.handleSubmit} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Update</button>
        </div>
      </form>
    </>
  );
};

export default Form;
