import { fetchRequest } from "../../utils";
import { 
    ADD_NODE, 
    RESET_TREE, 
    SET_TREE, 
    UPDATE_NODE,
    COPY_PASTE_NODES,
    CUT_PASTE_NODES,
    TOGGLE_COMPLETED_NODES,
    TOGGLE_DELETED_NODES,
    DOMAIN_NAME,
    UNCOMPLETE_COMPLETED_NODES,
    UN_DEFERED_DESCENDANTS,
    SET_CURRENT_TASK,
    SET_IS_PAUSED,
    UPDATE_MULTIPLE_NODES,
    DELETE_NODE
} from "../../constants";
import { toast } from "react-toastify";
import { 
    setChallengesOfUser,
    setAddNodeModalOpen, 
    setNavigatePointerNodeId, 
    setParentIdOfNodeTobeCreated,
    getStateOfUser,
    setRoutinesOfUser,
    setCurrentTask,
    setTimingAlertModalOpen,
    createPlanningDuration
} from "./index";

import { totalExploitationDuration } from "../../utils/initialCalculations";
import store, { RootState } from "../store";
import {cogniPulse1, confettiRise} from "../../assets/sounds";

// Define interfaces for the expected response types
interface ApiResponse {
    success: boolean;
    message?: string;
    nodes?: Node[];
    node?: Node;
    newSourceNode?: Node;
    sourceParentId?: string;
    runningTask?: Node
}

interface Node {
    _id: string;
    parent: string | null;
    children?: Node[];
    processed?: boolean;
    recurring?: {
        frequency?: string;
    };
    taskExploitationDuration?: number;
    taskExploitationDurationAsArray?: number[];
    startTimeInMs: any
    startTime: any 
    // Add other properties as needed
}

// Updated makeTreeFromArray function with types
function makeTreeFromArray(rootNode: Node, allNodes: any): Node {
    let nodes = 0;

    function visit(node: Node) {
        if (node.processed) {
            return;
        }

        node.processed = true;
        nodes++;
        
        if (node.children && node.children.length > 0) {
            const child = allNodes.filter((d:any) => d.parent === node._id);
            const children = node.children.map(nodeId => {
                let nd = child.find((ch:any) => ch._id === nodeId);
                if(nd){
                    return nd;
                }
                // const c = allNodes.find((d:any) => d._id === nodeId);
                // console.log(nd,nodeId,c);
                return nodeId;
            }).filter(c => c._id);
            
            node.children = children;
        } 
        else {
            node.children = [];
        }

        if (node.children) {
            node.children.forEach(child => visit(child));
        }
    }
    visit(rootNode);

    if (allNodes.length !== nodes) {
        console.log({ all: allNodes.length, nodes });
    }

    // root node id of hb = 622b4c4462e200a6f61414eb
    // const ids:any = [];

    // allNodes.forEach((n:any) => {
    //     const f = allNodes.find((nd:any) => n.parent === nd._id);
    //     if(!n.parent){
    //         console.log(n);
    //     }
    // });

    // console.log(ids);
    

    return rootNode;
}

export const getTreeData = () => async (dispatch: any) => {
    try {
        dispatch({ type: SET_TREE, payload: null });
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/?isDeleted=false`, "GET", {});
        console.log(res);

        if (res.success) {
            if (res.nodes?.length === 0) {
                dispatch(setAddNodeModalOpen(true));
                dispatch(setParentIdOfNodeTobeCreated(""));
                return;
            }

            // const idsToExclude = ["622b4c4462e200a6f6141511"]

            // const root = res.nodes?.find(node => {
            //     const n = res.nodes?.find(n => (n._id === node.parent && idsToExclude.indexOf(n._id) === -1));
            //     return n !== undefined;
            // });
            const root = res.nodes?.find(node => node.parent === null);
            const tree = makeTreeFromArray(root as Node, res.nodes);
            dispatch(getStateOfUser());
            dispatch({ type: SET_TREE, payload: tree });
            dispatch(setChallengesOfUser());
            dispatch(setRoutinesOfUser());
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: RESET_TREE });
    }
}

export const createNode = (node: Node) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes`, "POST", { ...node });
        console.log(res);
        if (res.success) {
            toast.success(res.message);
            const newNode:any = { ...res.node, children: [] };
            dispatch({ type: ADD_NODE, payload: { newNode } });
            dispatch(setNavigatePointerNodeId(newNode._id));
            setTimeout(() => {
                dispatch(setNavigatePointerNodeId(newNode.parent));
            }, 0);

            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: res.node?._id});
        } else {
            toast.error(res.message);
            
            if(node.parent)
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: node.parent});
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateNode = (node: any) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/${node._id}`, "PUT", { ...node });
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UPDATE_NODE, payload: res.node });
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: node?._id});
        } else {
            toast.error(res.message);
            await createPlanningDuration({startTime: node.startTimeInMs,endTime: new Date(),node: node?._id});
        }
    } catch (error) {
        console.error(error);
    }
}

export const deleteNode = (nodeId: string, parentNodeId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/${nodeId}`, "DELETE", {});
        if (res.success) {
            toast.success("Deleted Successfully!");
            dispatch({ type: DELETE_NODE, payload: { nodeId, parentNodeId } });
        } else {
            console.error(res);
        }
    } catch (error) {
        console.error(error);
    }
}

export const rankNodesManually = (parent: string, rankedNodes: Node[]) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/rankNodes/${parent}`, "PUT", { rankedNodes });
        if (res.success) {
            // toast.success(res.message);
            dispatch(getTreeData());
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const copyPasteNodes = (sourceId: string, destinationId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/copy/source/${sourceId}/destination/${destinationId}`, "PUT", {});
        
        console.log(res);
        
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: COPY_PASTE_NODES, payload: { newSourceNode: res.newSourceNode, destinationId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const cutPasteNodes = (sourceId: string, destinationId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/cut/source/${sourceId}/destination/${destinationId}`, "PUT", {});
        
        // console.log(res);

        if (res.success) {
            toast.success(res.message);
            dispatch({ type: CUT_PASTE_NODES, payload: { sourceId, sourceParentId: res.sourceParentId, destinationId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const toggleCompletedNodes = (isCompletedNodesVisible: boolean) => (dispatch: any) => {
    dispatch({ type: TOGGLE_COMPLETED_NODES, payload: isCompletedNodesVisible });
}

export const toggleDeletedNodes = (isDeletedNodesVisible: boolean) => (dispatch: any) => {
    dispatch({ type: TOGGLE_DELETED_NODES, payload: isDeletedNodesVisible });
}

export const unCompleteCompletedNodes = (arrOfIds: string[]) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/un-complete/completed-nodes`, "PUT", { arrOfIds });
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UNCOMPLETE_COMPLETED_NODES, payload: arrOfIds });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const deferTask = ({ id, numberOfTasks, time }: { id: string; numberOfTasks?: number; time: string }) => async (dispatch: any) => {
    try {
        const nodeId = id;
        const body = {
            time: new Date(time),
            deferByTask: numberOfTasks,
            deferByTaskDate: new Date().getTime(),
            endTime: new Date().getTime()
        }

        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/defer/${nodeId}`, "PUT", body);
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: SET_IS_PAUSED, payload: false });
            if (res.runningTask) {
                dispatch({ type: UPDATE_MULTIPLE_NODES, payload: [res.node, res.runningTask] });
            } else {
                dispatch({ type: UPDATE_NODE, payload: res.node });
            }
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const unDeferTask = (nodeId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/undefer/${nodeId}`, "PUT", {});
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UPDATE_NODE, payload: res.node });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const unDeferDescendantsTasks = (descendantsIds: string[]) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/descendants/undefer`, "PUT", { descendantsIds });
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: UN_DEFERED_DESCENDANTS, payload: descendantsIds });
        } else {
            toast.error(res.message);
        }
    } catch (error
    ) {
        console.error(error);
    }
}

export const getChildren = async (parentId: string): Promise<ApiResponse> => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/children/${parentId}`, "GET", {});
        return res;
    } catch (error) {
        console.error(error);
        throw error; // Propagate the error for handling in the calling function
    }
}

interface Ifuncs {
    preRun: () => void
    runIfSuccess: () => void
    runIfFails: () => void
}

export const playTask = (nodeId: string, startTime: string, funcs:Ifuncs) => async (dispatch: any) => {
    try {
        funcs.preRun();
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/play/${nodeId}`, "PUT", { startTime });
     
        if (res.success) {
            funcs.runIfSuccess();
            cogniPulse1.volume = .1;
            cogniPulse1.play();
            toast.success("Task is running");
            // console.log(res.node);
            // dispatch({ type: UPDATE_NODE, payload: res.node });
            const state = store.getState();
            // console.log(state.globalStates.currentTask);
            dispatch(setCurrentTask({...state.globalStates.currentTask,startTime: res.node?.startTime }));
        } 
        else {
            toast.error("Unable to play task");
            funcs.runIfFails();
        }
    } catch (error) {
        console.error(error);
    }
}

export const pauseTask = (nodeId: string, endTime: string, isPause: boolean,funcs: Ifuncs) => async (dispatch: any) => {
    interface IRes extends ApiResponse {
        newExploitationDuration: any
    }

    try {
        funcs.preRun();
        
        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/task/pause/${nodeId}`, "PUT", { endTime });
        
        // console.log(res);
        
        if (res.success) {
            funcs.runIfSuccess();
            toast.success("Task Paused!");
            dispatch({ type: SET_IS_PAUSED, payload: isPause !== undefined ? isPause : true });
            dispatch({ type: UPDATE_NODE, payload: res.node });
            const payload:any = res.node;
            if(payload){
                payload.taskExploitationDurationAsArray = payload.taskExploitationDuration;
                const isRecurring = payload.recurring?.frequency ? true : false;
                payload.taskExploitationDuration = totalExploitationDuration(payload.taskExploitationDuration, isRecurring);
                dispatch({ type: SET_CURRENT_TASK, payload });
            }
        } else {
            toast.error("Unable to Pause!");
            funcs.runIfFails();
        }
    } catch (error) {
        console.error(error);
    }
}

export const extendTaskDuration = (nodeId: string, data: any) => async (dispatch: any) => {
    interface IRes extends ApiResponse {
        newExploitationDuration: any
    }

    try {
        const res: IRes = await fetchRequest(`${DOMAIN_NAME}/nodes/task/extend-task-duration/${nodeId}`, "PUT", data);
        
        // console.log(res);
        
        if (res.success) {
            toast.success(res.message);
            const payload:any = res.node;
            if(payload){
                const rootState:RootState = store.getState();
                payload.taskExploitationDurationAsArray = payload.taskExploitationDuration;
                const isRecurring = payload.recurring?.frequency ? true : false;
                payload.nodeProductivityValue = rootState.globalStates.currentTask?.nodeProductivityValue;
                payload.taskExploitationDuration = totalExploitationDuration(payload.taskExploitationDuration, isRecurring);
                dispatch({ type: SET_CURRENT_TASK, payload });
            }
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const completeTask = (nodeId: string,nodeProductivityValue: number, endTime?: string, okrGradePercentage?: number, wereYouWorking?: boolean) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/complete/${nodeId}`, "PUT", { endTime, okrGradePercentage, wereYouWorking,nodeProductivityValue });
        if (res.success) {
            confettiRise.volume = .1
            confettiRise.play();
            toast.success(res.message);
            dispatch(setTimingAlertModalOpen(false));
            dispatch({ type: SET_IS_PAUSED, payload: false });
            dispatch({ type: UPDATE_NODE, payload: res.node });
            // const state = store.getState();
            // dispatch(setCurrentTask({...state.globalStates.currentTask,startTime: null}));
        } 
        else {
            dispatch(setTimingAlertModalOpen(true));
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const completeTaskLocal = async (nodeId: string,nodeProductivityValue: number, endTime?: string, okrGradePercentage?: number, wereYouWorking?: boolean) =>  {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/complete/${nodeId}`, "PUT", { endTime, okrGradePercentage, wereYouWorking,nodeProductivityValue });
        
        return res;
    } catch (error) {
        console.error(error);
    }
}

export const unCompleteRecurringTask = (taskId: string) => async (dispatch: any) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/task/recurring/un-complete/${taskId}`, "PUT", {});
        if (res.success) {
            toast.success(res.message);
            dispatch({ type: SET_IS_PAUSED, payload: false });
            dispatch({ type: UPDATE_NODE, payload: res.node });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const getAllTempDeletedNodes = async (): Promise<ApiResponse> => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/temp/deleted/nodes`, "GET", {});
        return res;
    } catch (error) {
        console.error(error);
        throw error; // Propagate the error for handling in the calling function
    }
}

export const updatePopupCameUpTime = async (nodeId: string, popupCameUpTime: Date) => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/${nodeId}`, "PUT", { popupCameUpTime });
        if (res.success) {
            console.log(res);
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const getRunningTask = async () => {
    try {
        const res: ApiResponse = await fetchRequest(`${DOMAIN_NAME}/nodes/running/task`, "GET", { });
        
        return res;
    } catch (error) {
        console.error(error);
    }
}

export const getTaskById = async (taskId: string) => {

    interface Ires {
        success: boolean;
        message: string;
        node: any;
    }

    try {
        const res: Ires = await fetchRequest(`${DOMAIN_NAME}/nodes/${taskId}`, "GET", { });
        
        return res;
        
    } catch (error) {
        console.error(error);
    }
}


interface IGetCompletedNodes {
    startDate: Date;
    endDate: Date;
}

export const getCompletedNodes = async ({startDate,endDate}: IGetCompletedNodes) => {

    interface Ires {
        success: boolean;
        message: string;
        nodes: any;
        allExploitationDurations: any;
    }

    try {
        const res: Ires = await fetchRequest(`${DOMAIN_NAME}/nodes/completed-nodes/today?startDate=${startDate}&endDate=${endDate}`, "GET", { });
        
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

