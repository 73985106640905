// auth 
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

// tree 
export const SET_TREE = "SET_TREE";
export const ADD_NODE = "ADD_NODE";
export const UPDATE_NODE = "UPDATE_NODE";
export const UPDATE_MULTIPLE_NODES = "UPDATE_MULTIPLE_NODES";
export const DELETE_NODE = "DELETE_NODE";
export const COPY_PASTE_NODES = "COPY_PASTE_NODES";
export const CUT_PASTE_NODES = "CUT_PASTE_NODES";
export const TOGGLE_COMPLETED_NODES = "TOGGLE_COMPLETED_NODES";
export const TOGGLE_DELETED_NODES = "TOGGLE_DELETED_NODES";
export const RESET_TREE = "RESET_TREE";
export const UN_DEFERED_DESCENDANTS = "UN_DEFERED_DESCENDANTS";


// tree to display 
export const SET_TREE_TO_DISPLAY = "SET_TREE_TO_DISPLAY";


// EVENTS 
export const SET_EVENTS = "SET_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";


// CHALLENGES 
export const ADD_CHALLENGE = "ADD_CHALLENGE";
export const REMOVE_CHALLENGE = "REMOVE_CHALLENGE";
export const SET_CHALLENGES = "SET_CHALLENGES";
export const UPDATE_CHALLENGE = "UPDATE_CHALLENGE";


// ROUTINES 
export const SET_ROUTINES = "SET_ROUTINES";
export const ADD_ROUTINE = "ADD_ROUTINE";
export const UPDATE_ROUTINE = "UPDATE_ROUTINE";
export const REMOVE_ROUTINE = "REMOVE_ROUTINE";



// global states 
export const SET_ADD_NODE_MODAL_OPEN = "SET_ADD_NODE_MODAL_OPEN";
export const SET_PARENT_ID = "SET_PARENT_ID";
export const SET_DEPTH = "SET_DEPTH";
export const SET_UPDATE_NODE_MODAL_OPEN = "SET_UPDATE_NODE_MODAL_OPEN";
export const SET_COPIED_NODE_ID = "SET_COPIED_NODE_ID";
export const SET_CUT_NODE_ID = "SET_CUT_NODE_ID";
export const SET_NAVIGATE_POINTER_NODE_ID = "SET_NAVIGATE_PINTER_NODE_ID";
export const SET_PANNING_TREE_CENTER_NODE_AXISES = "SET_PANNING_TREE_CENTER_NODE_AXISES";
export const TOGGLE_IS_COMPLETED_NODES_VISIBLE = "TOGGLE_IS_COMPLETED_NODES_VISIBLE";
export const TOGGLE_IS_DELETED_NODES_VISIBLE = "TOGGLE_IS_DELETED_NODES_VISIBLE";
export const TOGGLE_IS_PANNING_TREE_CENTER = "TOGGLE_IS_PANNING_TREE_CENTER";
export const SET_FOCUS_BLOCKS = "SET_FOCUS_BLOCKS";
export const SET_CURRENT_TASK = "SET_CURRENT_TASK";
export const SET_CURRENT_TASKS_PARENTS = "SET_CURRENT_TASKS_PARENTS";
export const SET_TIMING_ALERT_MODAL_OPEN = "SET_TIMING_ALERT_MODAL_OPEN";
export const SET_START_TIME = "SET_START_TIME";
export const SET_END_TIME = "SET_END_TIME";
export const SET_UPCOMING_TASK = "SET_UPCOMING_TASK";
export const SET_UPCOMING_TASKS_PARENTS = "SET_UPCOMING_TASKS_PARENTS";
export const SET_UPCOMMING_TASK_MODAL_OPEN = "SET_UPCOMMING_TASK_MODAL_OPEN";
export const SET_PARENT_TASK_COMPLETE_ALERT_MODAL_OPEN = "SET_PARENT_TASK_COMPLETE_ALERT_MODAL_OPEN";
export const SET_POWER_LAW = "SET_POWER_LAW";
export const SET_CAPTURE_PARK_NAVIGATOR_ID = "SET_CAPTURE_PARK_NAVIGATOR_ID";
export const UNCOMPLETE_COMPLETED_NODES = "UNCOMPLETE_COMPLETED_NODES";
export const SET_COLORS = "SET_COLORS";
export const SET_MANUALLY_SCHEDULED_NODE_ID = "SET_MANUALLY_SCHEDULED_NODE_ID";
export const SET_WERE_YOU_WORKING = "SET_WERE_YOU_WORKING";
export const SET_IS_PAUSED = "SET_IS_PAUSED";
export const SET_IS_FULL_VIEW_ON = "SET_IS_FULL_VIEW_ON";
export const SET_IS_CURRENT_TASK_RUNNING = "SET_IS_CURRENT_TASK_RUNNING";
export const SET_OBJECTIVE_ARR = "SET_OBJECTIVE_ARR";
export const SET_DEADLINE = "SET_DEADLINE";
export const SET_NODE_ID_TO_BE_EDITED = "SET_NODE_ID_TO_BE_EDITED";





















