import React from "react";
import Modal from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import Form from "./Form";
import {
  pauseTask,
} from "../../store/actions";
import {
  setUpcomingTaskModalOpen,
  setCurrentTask,
  setCurrentTasksParents,
  setUpcomingTask,
  setUpcomingTasksParents,
  setStartTime,
} from "../../store/actions/globalStates";

interface RootState {
  globalStates: {
    isUpcomingTaskModalOpen: boolean;
    startTime: number | null;
    currentTask: any; // Replace `any` with your actual task type
    upcomingTask: any; // Replace `any` with your actual task type
    upcomingTasksParents: any[]; // Replace `any` with your actual task parent type
  };
}

const Index: React.FC = () => {
  const dispatch:any = useDispatch();
  
  const isUpcomingTaskModalOpen = useSelector((state: RootState) => state.globalStates.isUpcomingTaskModalOpen);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const upcomingTask = useSelector((state: RootState) => state.globalStates.upcomingTask);
  const upcomingTasksParent = useSelector((state: RootState) => state.globalStates.upcomingTasksParents);

  const handleSubmit = (values: { isWorking: string; okrGradePercentage: number; extraMinutes: number; }, e: any) => {
    if (currentTask?.startTime) {
      const funcs = {
          preRun: () => {},
          runIfSuccess: () => {},
          runIfFails: () => {}
      }
      dispatch(pauseTask(currentTask._id, "", false,funcs));
      dispatch(setStartTime(null));
    }
    
    dispatch(setCurrentTask(upcomingTask));
    dispatch(setCurrentTasksParents(upcomingTasksParent));
    dispatch(setUpcomingTask(null));
    dispatch(setUpcomingTasksParents(null));
    dispatch(setUpcomingTaskModalOpen(false));
    e.resetForm();
  };

  const onEscape = () => {
    // Add any specific functionality you want on escape
  };

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isUpcomingTaskModalOpen}
      setIsOpen={(bool) => { dispatch(setUpcomingTaskModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
      showTopArrow={false}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0 }}
        onSubmit={handleSubmit}
        validate={() => { }}
      >
        {(e) => <Form e={e} />}
      </Formik>
    </Modal>
  );
};

export default Index;
