import { useDispatch } from "react-redux";
import { Button } from "../../../components"
import { setNavigatePointerNodeId, setUpdateNodeModalOpen } from "../../../store/actions";
import { Main } from "../../../layout";
import { SET_NODE_ID_TO_BE_EDITED } from "../../../constants";
import { AppDispatch } from "../../../store/store";
import moment from "moment";
import { useState } from "react";


interface IPastDeadlines {
    pastDeadlinesAtProjctAndObjective: any[]
}

interface IhandleClick {
    e: React.MouseEvent<HTMLButtonElement>;
    node: any
}

export const PastDeadlines: React.FC<IPastDeadlines> = ({pastDeadlinesAtProjctAndObjective}) => {
    const dispatch:AppDispatch = useDispatch();
    const [viewDeadline, setViewDeadline] = useState(true);

    const handleClick = ({e,node}:IhandleClick) => {
        e.preventDefault();
        // console.log(node._id);
        dispatch({type: SET_NODE_ID_TO_BE_EDITED,payload: node._id});
        dispatch(setUpdateNodeModalOpen(true));
    }

    const handleView = (node:any) => {
        dispatch(setNavigatePointerNodeId(node._id));
        setViewDeadline(false);
    }
    

    return <Main>
       <div className={`${!viewDeadline? "h-[64px]":"h-[100vh]"} mx-auto w-[500px] w-[100vw] h-[100vh] border p-2 fixed z-[2000] top-0 left-0 px-10 bg-white`}>
        {
            viewDeadline && <>
                            <h1 className="text-xl font-bold my-4 text-center">Past Deadlines At Project & Objective</h1>
                            <div className="flex flex-col gap-2">
                                {
                                    pastDeadlinesAtProjctAndObjective.map((node,idx) => (
                                        <div key={idx} className="flex items-center gap-4 shadow-md px-2 py-4 rounded-md">
                                            <p onClick={() => handleView(node)} className="flex-1"><span className="bg-red-600 text-white p-1 rounded-md">{node.deadline? moment(node.deadline).format("LL"):"Not Set"}</span> {node.objective}</p>
                                            <Button onClick={(e)=> handleClick({e,node})}>Set Now</Button>
                                        </div>
                                    ))
                                }
                            </div>
            </>
        }
        {
            !viewDeadline && <Button onClick={() => setViewDeadline(true)}>View Deadlines</Button>
        }
       </div>
    </Main>
}