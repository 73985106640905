import React, { useEffect, useRef, useState } from 'react';
import { Main } from "../../layout";
import { useDispatch, useSelector } from 'react-redux';
import { hierarchy, partition, select, transition } from 'd3';
import Node from './components/Node';
import {
  initialCalculations,
  treeCalculations
} from "../../utils";
import RankNodes from "../RankNodes/Index";
import {
  setAddNodeModalOpen,
  setUpdateNodeModalOpen,
  setNavigatePointerNodeId,
  setParentIdOfNodeTobeCreated,
  setCopiedNodeId,
  setCutNodeId,
  cutPasteNodes,
  copyPasteNodes,
  setIsFullViewOn,
  setNodeIdToBeEdited
} from '../../store/actions';
import { Loading } from "../../components";
import FullView from './components/FullView';
import { RootState } from '../../store/store';
import { PastDeadlines } from './components/PastDeadlines';
import { parentNodeComplete, parentNodeDelete, recurringCompletion } from '../../utils/initialCalculations';

const heightOfLabels = 20;
const topTitles = ["Life", "Life Divisions", "Categories of Improvement", "Projects & Objectives"];
let rootData: any = undefined;

interface Dimensions {
  height: number;
  width: number;
}

interface TreeNode {
  // Define the structure of the tree node here
  x0: number;
  x1: number;
  data: any;
  descendants: () => Array<TreeNode>;
}



const Index: React.FC = () => {
  const myTree = useSelector((state: RootState) => state.tree); // Replace `any` with your Redux state type
  const data = JSON.parse(JSON.stringify(myTree));
  const containerEl = useRef<HTMLDivElement>(null);
  const events:any = useSelector((state: RootState) => state.events)?.filter((ev: any) =>
    (new Date().getDay() === new Date(ev.startTime).getDay()) && (new Date().getDay() === new Date(ev.endTime).getDay())
  );
  const isCompletedNodesVisible = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible);
  const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
  const [dimensions, setDimensions] = useState<Dimensions>({ height: 800, width: 1200 - heightOfLabels });
  const isFullViewOn = useSelector((state: RootState) => state.globalStates.isFullViewOn);
  const focusRef = useRef<SVGElement | null>(null);
  const orientation = useScreenOrientation(); // Make sure to define the `useScreenOrientation` hook
  const [isOrdered, setIsOrdered] = useState(false);
  const [pastDeadlinesAtProjctAndObjective, setPastDeadlinesAtProjctAndObjective] = useState<any[]|null>(null);


  useEffect(() => {
    if (containerEl.current) {
      if(window.screen.width > 449 && window.screen.width < 1023){
        setDimensions({
          width: window.screen.width,
          height: window.screen.height - heightOfLabels - 64
        });
      }
      else {
        setDimensions({
          width: containerEl.current.clientWidth,
          height: containerEl.current.clientHeight - heightOfLabels
        });
      }
    }

    if(myTree){
        const treeData = JSON.parse(JSON.stringify(myTree));

        visit(treeData);

        function visit(d: any): void {
          
            recurringCompletion(d);
        
            d.children?.forEach((child:any) => visit(child));
        
            parentNodeComplete(d);
            parentNodeDelete(d);
        }

        let nodeWithoutDeadline:any[] = [];
        treeData?.children.forEach((child:any) => {
          child?.children.forEach((child1:any) => {
            child1?.children.forEach((child2:any) => {
              if((!child2.deadline || new Date(child2.deadline) < new Date()) && !child2.isDeleted && !child2.isTempCompleted && !child2.isCompleted && !child2.isRecurringCompleted){
                // console.log(child2.isDeleted , child2.isTempCompleted , child2.isCompleted , child2.isRecurringCompleted);
                nodeWithoutDeadline.push({objective: child2.objective, _id: child2._id, deadline: child2.deadline});
              }
            });
          });
        });

        if(nodeWithoutDeadline.length > 0){
          setPastDeadlinesAtProjctAndObjective(nodeWithoutDeadline);
        }
        else {
          setPastDeadlinesAtProjctAndObjective(null);
        }
    }
    
  }, [myTree, manuallyScheduleNodeId,orientation]);

  
  if (!data) {
    return <Main>
              <div className="w-full h-full flex justify-center items-center">
                {
                  isOrdered && <h1 className='font-bold text-3xl'>Ranking in progress, please wait...</h1>
                }
                {
                  !isOrdered &&  <Loading />
                }
              </div>
            </Main>;
  }

  const rectHeight = (d: TreeNode) => {
    return d.x1 - d.x0 - Math.min(1, (d.x1 - d.x0) / 2) + 1;
  };


  // if(pastDeadlinesAtProjctAndObjective){
  //   return <PastDeadlines pastDeadlinesAtProjctAndObjective={pastDeadlinesAtProjctAndObjective}/>
  // }


  if (isFullViewOn) {
    return (
      <Main>
        <div className="h-[calc(100vh-64px)] w-full" ref={containerEl}>
          <FullView data={data} width={dimensions.width} height={dimensions.height + heightOfLabels} />
        </div>
      </Main>
    );
  }

  const root = calc(JSON.parse(JSON.stringify(data)), events, dimensions, focusRef, isCompletedNodesVisible);
  rootData = root;

  

  return (
    <Main>
      <div className="h-[calc(100vh-64px)] w-full" ref={containerEl}>
        {(focusRef.current) && <Tree setIsOrdered={setIsOrdered} setIsFullViewOn={setIsFullViewOn} focusRef={focusRef} dimensions={dimensions} root={root} rectHeight={rectHeight} />}
      </div>
      {
        pastDeadlinesAtProjctAndObjective && <PastDeadlines pastDeadlinesAtProjctAndObjective={pastDeadlinesAtProjctAndObjective}/>
      }
    </Main>
  );
};

export default Index;


interface Dimensions {
  height: number;
  width: number;
}

interface TreeNode {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  data: any;
  parent?: TreeNode;
  children?: TreeNode[];
  descendants: () => TreeNode[];
  each: (func: (node: TreeNode) => void) => void;
  target?: any;
  depth: number;
}

interface TreeProps {
  dimensions: Dimensions;
  root: any;
  rectHeight: (d: TreeNode) => number;
  focusRef: any;
  setIsFullViewOn: (isFullViewOn: boolean) => void;
  setIsOrdered: any;
}

const Tree: React.FC<TreeProps> = ({ setIsOrdered,dimensions, root, rectHeight, focusRef, setIsFullViewOn }) => {
  const myRef = useRef<SVGGElement>(null);
  const SVGRef = useRef<SVGSVGElement>(null);
  const [currentNodeToRank, setCurrentNodeToRank] = useState<TreeNode | null>(null);
  const navigatorId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);
  const [isLoaded, setIsLoaded] = useState(false);
  const titleContainer = useRef<HTMLDivElement>(null);
  const dispatch:any = useDispatch();
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId);
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const copiedNodes = root.descendants().find((d:any) => d.data._id === (cutNodeId || copyNodeId))?.descendants().map((item:any) => item.data._id) || [];

  

  const handleClick = (e: React.MouseEvent, d: TreeNode) => {
    if (navigatorId === d.data._id && d.parent) {
      dispatch(setNavigatePointerNodeId(d.parent.data._id));
      return;
    }
    if (d.children || d.parent) {
      dispatch(setNavigatePointerNodeId(d.data._id));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    const key = e.key;
    
    if (key === "ArrowLeft" && focusRef.current?.parent) {
      dispatch(setNavigatePointerNodeId(focusRef.current.parent.data._id));
    }
    if (key === "ArrowRight" && focusRef.current?.children?.length !== 0) {
      dispatch(setNavigatePointerNodeId(focusRef.current.children[0].data._id));
    }
    if (key === "ArrowUp" && focusRef.current?.parent) {
      const index = focusRef.current.parent.children?.findIndex((n:any) => n.data._id === focusRef.current.data._id) || -1;
      const target = focusRef.current.parent.children?.[index - 1];
      if (target) {
        dispatch(setNavigatePointerNodeId(target.data._id));
      } else {
        dispatch(setNavigatePointerNodeId(focusRef.current.parent.children?.[focusRef.current.parent.children.length - 1]?.data._id || ""));
      }
    }
    if (key === "ArrowDown" && focusRef.current?.parent) {
      const index = focusRef.current.parent.children?.findIndex((n:any) => n.data._id === focusRef.current.data._id);
      const target = focusRef.current.parent.children?.[index + 1];

      if (target) {
        dispatch(setNavigatePointerNodeId(target.data._id));
      } 
      else {
        dispatch(setNavigatePointerNodeId(focusRef.current.parent.children?.[0]?.data._id || ""));
      }
    }
    if (!e.ctrlKey && key === 'Enter') {
      root.each((n:any) => {
        if (n.data._id === navigatorId) {
          dispatch(setNodeIdToBeEdited(n.data._id));
          dispatch(setUpdateNodeModalOpen(true));
          return;
        }
      });
    }
    if (e.ctrlKey && key === 'Enter') {
      dispatch(setAddNodeModalOpen(true));
      dispatch(setParentIdOfNodeTobeCreated(navigatorId || ""));
    }
    if (e.ctrlKey && key === 'c') {
      dispatch(setCopiedNodeId(navigatorId || ""));
      // toast.success("Node Copied!");
    }
    if (e.ctrlKey && key === 'x') {
      dispatch(setCutNodeId(navigatorId || ""));
      // toast.success("Node Cut!");
    }
    if (e.ctrlKey && key === 'v') {
      if (cutNodeId) {
        dispatch(cutPasteNodes(cutNodeId, navigatorId || ""));
        dispatch(setCutNodeId(""));
      }
      if (copyNodeId) {
        dispatch(copyPasteNodes(copyNodeId, navigatorId || ""));
        dispatch(setCopiedNodeId(""));
      }
      // toast.success("Node Pasted!");
    }
    if (key === "Escape") {
      dispatch(setCopiedNodeId(""));
      dispatch(setCutNodeId(""));
    }
  };

  const handleRankButtonClick = (i: number) => {
    const firstEl = root.descendants().find((node:any) => node.data._id === navigatorId);
    if (!firstEl) {
      return;
    }
    if (i === 0) {
      setCurrentNodeToRank(firstEl);
      return;
    }
    if (i === 1) {
      if (firstEl.children?.[0]) {
        setCurrentNodeToRank(firstEl.children[0]);
      }
      return;
    }
  };

  useEffect(() => {
    const handleNavigate = (navigateToNode: string | undefined, isRoot: boolean) => {
      let p: TreeNode | undefined = undefined;
      if (navigateToNode) {
        root.each((n:any) => {
          if (n.data._id === navigateToNode) {
            p = n;
          }
        });
      }
      if (!p) {
        return;
      }
      focusRef.current = p;
      root.each((d:any) => {
        d.target = {
          x0: ((d.x0 - p!.x0) / (p!.x1 - p!.x0)) * dimensions.height,
          x1: ((d.x1 - p!.x0) / (p!.x1 - p!.x0)) * dimensions.height,
          y0: d.y0 - p!.y0,
          y1: d.y1 - p!.y0
        };
      });
      animateNodes(false);
      function animateNodes(isRoot: boolean) {
        const t = transition().duration(725);
        const parent = select(myRef.current);
        parent.selectAll("g")
          .data(root.descendants())
          .transition(t)
          .attr('transform', (d:any, i) => `translate(${isRoot ? d.y0 : d.target.y0},${isRoot ? d.x0 : d.target.x0})`);
        parent.selectAll("foreignObject")
          .data(root.descendants())
          .transition(t)
          .attr('height', function (d:any) {
            const height = rectHeight(isRoot ? d : d.target);
            const elem:any = this;
            const bar = elem.querySelector(".progress-bar") as HTMLElement;
            const info = elem.querySelector(".other-info") as HTMLElement;
            const buttons = elem.querySelector(".crud-buttons") as HTMLElement;
            if (bar && info) {
              if (height < 76) {
                bar.style.display = "none";
              } else {
                bar.style.display = "flex";
              }
              if (height < 30) {
                info.style.display = "none";
              } else {
                info.style.display = "flex";
              }
              if (height < 116) {
                buttons.style.display = "none";
              } else {
                buttons.style.display = "unset";
              }
            }
            return height;
          });
      }
      titleContainer.current!.childNodes.forEach((div, i) => {
        const text = (div as HTMLElement).querySelector(".text") as HTMLElement;
        const depth = focusRef.current!.depth + i;
        text.innerHTML = depth < topTitles.length ? topTitles[depth] : `OKR L${depth - 3}`;
      });
    };

    if (isLoaded && navigatorId) {
      handleNavigate(navigatorId, false);
    } else {
      setIsLoaded(true);
    }
  }, [navigatorId, isLoaded, dimensions.height, rectHeight, focusRef, root]);

  // focus on svg element when program load 
  useEffect(() => {
    if (myRef) {
      if (myRef.current?.parentElement?.focus) {
        myRef.current.parentElement.focus();
      }
    }
  }, [myRef]);

  useEffect(() => {
    const handleKeyPressInside = (e: KeyboardEvent) => {
      const key = e.key;
      if (key === "ArrowLeft" && focusRef.current?.parent) {
        dispatch(setNavigatePointerNodeId(focusRef.current.parent.data._id));
      }
      if (key === "ArrowRight" && focusRef.current?.children?.length !== 0) {
        dispatch(setNavigatePointerNodeId(focusRef.current.children[0].data._id));
      }
      if (key === "ArrowUp" && focusRef.current?.parent) {
        const index = focusRef.current.parent.children?.findIndex((n:any) => n.data._id === focusRef.current.data._id) || -1;
        const target = focusRef.current.parent.children?.[index - 1];
        if (target) {
          dispatch(setNavigatePointerNodeId(target.data._id));
        } else {
          dispatch(setNavigatePointerNodeId(focusRef.current.parent.children?.[focusRef.current.parent.children.length - 1]?.data._id || ""));
        }
      }
      if (key === "ArrowDown" && focusRef.current?.parent) {
        const index = focusRef.current.parent.children?.findIndex((n:any) => n.data._id === focusRef.current.data._id);
        const target = focusRef.current.parent.children?.[index + 1];
 
        if (target) {
          dispatch(setNavigatePointerNodeId(target.data._id));
        } else {
          dispatch(setNavigatePointerNodeId(focusRef.current.parent.children?.[0]?.data._id || ""));
        }
      }
    };

    SVGRef.current?.addEventListener("touchmove", (e) => {
      e.preventDefault();
    });

    SVGRef.current?.addEventListener("swiped-left", (e) => {
      e.preventDefault();
      handleKeyPressInside({ key: "ArrowRight" } as KeyboardEvent);
    });
    SVGRef.current?.addEventListener("swiped-right", (e) => {
      e.preventDefault();
      handleKeyPressInside({ key: "ArrowLeft" } as KeyboardEvent);
    });
    SVGRef.current?.addEventListener("swiped-up", (e) => {
      e.preventDefault();
      handleKeyPressInside({ key: "ArrowDown" } as KeyboardEvent);
    });
    SVGRef.current?.addEventListener("swiped-down", (e) => {
      e.preventDefault();
      handleKeyPressInside({ key: "ArrowUp" } as KeyboardEvent);
    });

    return () => {
      document.removeEventListener("touchmove", () => { });
      document.removeEventListener("swiped-left", () => { });
      document.removeEventListener("swiped-right", () => { });
      document.removeEventListener("swiped-up", () => { });
      document.removeEventListener("swiped-down", () => { });
    };
  }, [focusRef, dispatch]);

  return (
    <>
      <div ref={titleContainer} className='flex justify-center bg-white text-xs' style={{ height: `${heightOfLabels}px` }}>
        {
          new Array(3).fill(1).map((item, i) => (
            <div key={i + item} className='flex-1 text-center flex justify-center items-center gap-2'>
              {
                i === 0 && <img title='Zoom Out' className='cursor-pointer w-3 h-3' onClick={() => dispatch(setIsFullViewOn(true))} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjElEQVR4nO2Zy2oUQRSGv1kYt26cEWMWo4/gQo2XVxB0InMRxPgAghcMQqI79QUEV5qFEIILk5Gsk+jGS97AjVvNKmJiMhNbD/wNtZgE26mqudgfFDSZnv/0X+lz6lQN5OT0JUeASWAWWAPWgR2Nb8AnfXYdKNGHXACaQAtI/nLYvYvAefqAE8CS83BtYAW4BZwCisABDbs+DdwGVnVv+r03wPFemWgA3/UgP4BHwOEM3zdjj4FNaWwANSIz48zmHDDahdYxYN7RmyYSDxXwl64LnnRvAruxzDQUyAJWAuhPOGaqBEzsNCfuhQoCTDk5Uw4RYMnJiZAUnJxphlgnElWYMcIzqkqYAOd8CjclaiU2FlaaLeaCz7ajpQUsyzrRLbbOtNXe2HXX3NDMLBOft4p9zYfYrMSs7YjNHcV+7kNsTWLWO8VmXLE/+BBbl1jM/EgpKfZXH2LbEhvZ556ky7EXB/X5z2ExsjEsr9Znn8lum6LYnFVsK8NDUX6f+RCblJhtT2PzTrEv+XpP0xbFS6uQsUVpAYd8iS5qZqyRi8UTxbSW3htnJLrVgzb+pG/xpjNDvvbpnTDtV4r1OkSAshamRNvRUNxXDDuZPBoqSM05fLCDAt9ccQ4fLhKYacfMlKfXrKD/RGpiVyc2xDKT5owdsv0rY05OuMNKb50IVJ2c2VRpzrLOFFVit5zGsN3BTI0IlJ1qlgZeVWsxrsV0RKOkv91V7+Q+9JwSu95LM+jIZiHjzwq2PXjZoRndy0yViBR1UPAC+KgSuq3F7QvwHniqh9pvW1DrBzO+sPLe6mDmKkNkpsEAMjFMZir/g5k6A2pmJzczIAVghgHlsvOaPWDAqcT8OTsn588M/AYBPTLpjNoTtwAAAABJRU5ErkJggg==" alt='img'/>
              }
              <span className='text'>{root.depth + i < topTitles.length ? topTitles[root.depth + i] : `OKR L${(root.depth - 3) - i}`}</span>
              {
                i < 2 && <button onClick={() => handleRankButtonClick(i)} className='cursor-pointer h-[15px] text-[10px] rounded-md pb-[1px] px-[5px] bg-blue-600 text-white'>Rank</button>
              }
            </div>
          ))
        }
      </div>
      <svg
        ref={SVGRef}
        style={{ display: currentNodeToRank ? "none" : "" }}
        tabIndex={0}
        onKeyDown={handleKeyPress}
        width={dimensions.width}
        height={dimensions.height}
        data-swipe-threshold="10"
        data-swipe-timeout="1000"
        data-swipe-ignore="false"
      >
        <g ref={myRef} transform='translate(0,0)'>
          {
            root.descendants().map((d:any) => (
              <g key={d.data._id} transform={`translate(${d.y0},${d.x0})`} >
                <foreignObject
                  className='node-container'
                  onClick={(e) => handleClick(e, d)}
                  width={(dimensions.width / 3)}
                  height={rectHeight(d)}>
                  <Node d={d} height={rectHeight(d)} setCurrentNodeToRank={setCurrentNodeToRank} copiedNodes={copiedNodes} />
                </foreignObject>
              </g>
            ))
          }
        </g>
      </svg>
      {
        currentNodeToRank && <RankNodes setCurrentNodeToRank={setCurrentNodeToRank} currentNodeToRank={currentNodeToRank} setIsOrdered={setIsOrdered}/>
      }
    </>
  );
};

interface TreeNode {
  isCompleted?: boolean;
  isDeleted?: boolean;
  isTempCompleted?: boolean;
  value?: number;
  children?: TreeNode[];
  [key: string]: any;
}

interface Dimensions {
  height: number;
  width: number;
}

function calc(data: any,events: any[],dimensions: Dimensions,focusRef: any,isCompletedNodesVisible: boolean) {

  initialCalculations(data);
  addValueKey(data);

  function addValueKey(node: TreeNode) {
    const allCompleted = node.children?.every(c => c.isCompleted || c.isDeleted || c.isTempCompleted);
    if (!node.children || allCompleted) {
      node.value = 1;
    }

    if (node.children) {
      node.children.forEach(item => {
        addValueKey(item);
      });
    }
  }

  let tempRoot = hierarchy<TreeNode>(data);

  treeCalculations(tempRoot, events, isCompletedNodesVisible);

  tempRoot = tempRoot.sum((d) => d.value || 0);

  const root = partition<TreeNode>()
    .size([dimensions.height, ((tempRoot.height + 1) * dimensions.width) / 3])
    (tempRoot);

  focusRef.current = root;

  return root;
}

export { calc };


interface Diem{
  width: number;
  height: number;
}

const useScreenOrientation = (): Diem => {
  const [diem, setDiem] = useState<Diem>({width: window.innerWidth,height: window.innerHeight});

  useEffect(() => {
    window.addEventListener('orientationchange', ()=> {
      setDiem({
          width: window.innerWidth,
          height: window.innerHeight
      });
    });
    return () => {
      window.removeEventListener('orientationchange', ()=> {});
    }
  }, []);

  return diem;
}

export function getTreeData() {
  return rootData;
}












