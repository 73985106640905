import React from 'react';
import {
  AddNodeModal,
  UpdateNodeModal,
  TimingAlertModal,
  UpCommingTaskModal,
  ParentTaskCompleteAlertModal,
} from "../../components";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface IndexProps {
  children: React.ReactNode; // Adjust this type based on how you expect to use the children prop
}

const Index: React.FC<IndexProps> = ({ children }) => { 
  const isUpdateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isUpdateNodeModalOpen);
  return (
    <div className='min-[450px]:relative h-[calc(100vh-64px)] w-full text-black'>
      {children}
      <AddNodeModal />
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      <TimingAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </div>
  );
}

export default Index;
