import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { removeChallenge, removeRoutine, setCurrentTask, setCurrentTasksParents, setIsFullViewOn, setManuallyScheduledNodeId, setNavigatePointerNodeId, toggleIsCompletedNodesVisible } from "../../../store/actions";
import { getTreeData } from "../../../pages/ICICLETree/Index";
import { Button } from "../../../components";
import { getCurrentTask } from "../../../utils";
import { SET_IS_PAUSED } from "../../../constants";

interface RoutinesAndChallengesProps {
    treeData: any; // Adjust type based on your treeData structure
}



const RoutinesAndChallenges: React.FC<RoutinesAndChallengesProps> = ({ treeData }) => {
    const dispatch:any = useDispatch();
    const [chalengeType, setChallengeType] = useState<"day" | "week">("day");
    const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
    const [isShowRoutines, setIsShowRoutines] = useState(false);
    const challenges = useSelector((state: RootState) => state.challenges);
    const routines = useSelector((state: RootState) => state.routines);
    const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
 

    const handleRemoveChallenge = (_id: string) => {
        dispatch(removeChallenge(_id));
    };

    const handleLoadTask = (item: any) => {
        dispatch(setIsFullViewOn(false));
        dispatch(setNavigatePointerNodeId(item.node._id));
        
        let node:any = undefined;

        // find node from tree 
        if (treeData) {
            const data = getTreeData(); // Ensure getTreeData is defined and returns the correct type

            test(data);
            function test(d: any) {
                if(d.data._id === item.node._id){
                    node = d;
                    return 
                }

                if (d.children) {
                    d.children.forEach((child:any) => test(child));
                }
            }
        }
        else {
            return;
        }

        if(!node){
            dispatch(toggleIsCompletedNodesVisible())
            return;
        }
        
        if(currentTask?.startTime){
            return;
        }

        // create arry of parent objective from item to project 
        let parentObjectives = [];
        let tempNode = node.parent;

        while (tempNode.depth > 2) {
            parentObjectives.push(tempNode.data);
            tempNode = tempNode.parent;
        }

        // find current task and paretn objective 
        const result = getCurrentTask(node.data,parentObjectives);
        
        dispatch({ type: SET_IS_PAUSED, payload: true });
        dispatch(setCurrentTask(result.currentTask));
        dispatch(setCurrentTasksParents(result.parentObjectives));

        if(!manuallyScheduleNodeId || manuallyScheduleNodeId[manuallyScheduleNodeId.length-1] !== item.node._id){
			if(manuallyScheduleNodeId){
			  dispatch(setManuallyScheduledNodeId([...manuallyScheduleNodeId,item.node._id]));
			}
			else {
			  dispatch(setManuallyScheduledNodeId([item.node._id]));
			}
		}
    };

    const handleRemoveRoutine = (routineId: string) => {
        dispatch(removeRoutine(routineId));
    };

    

    

    return (
        <>
            <div className='flex mt-2 border'>
                <button onClick={() => { setIsShowRoutines(false); }} className={`${!isShowRoutines ? "bg-white text-black" : ""} flex-1 text-center`}>
                    Challenges
                </button>
                <button onClick={() => { setIsShowRoutines(true); }} className={`${isShowRoutines ? "bg-white text-black" : ""} flex-1 text-center`}>
                    Routines
                </button>
            </div>

            {!isShowRoutines && (
                <div className='mt-3 p-2 bg-white rounded-md'>
                    <div className='flex items-center justify-between border-b pb-2'>
                        <Button style={{width: "100%"}} onClick={() => setChallengeType(p => p === "day" ? "week" : "day")}>
                            {chalengeType === "day" ? "Day" : "Week"} Challenges
                        </Button>
                    </div>
                    <div className='flex flex-col p-1'>
                        {challenges.filter(ch => ch.type === chalengeType).map((item, idx) => (
                            <div key={idx} style={{ backgroundColor: `${item.node.color}` }} className={`border-b p-2`}>
                                <div className='bg-gray-200 h-[5px] rounded-md'>
                                    <div style={{ width: `${item.node?.completedPortion}%` }} className='h-full rounded-md bg-blue-600' />
                                </div>
                                <div className='flex justify-between items-center'>
                                    <button onClick={() => handleLoadTask(item)} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1'>{item.node?.objective}</button>
                                    <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemoveChallenge(item._id)}  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {isShowRoutines && (
                <div className='mt-3 p-2 bg-white rounded-md'>
                    <div className='flex items-center justify-between border-b pb-2'>
                        <h6 className='mb-0'>Routines</h6>
                    </div>
                    <div className='flex flex-col p-1'>
                        {routines.map((item, idx) => (
                            <div key={idx} style={{ backgroundColor: `${item.node.color}` }} className={`border-b p-2`}>
                                <div className='bg-gray-200 h-[5px] rounded-md'>
                                    <div style={{ width: `${item.node?.completedPortion}%` }} className='h-full rounded-md bg-blue-600' />
                                </div>
                                <div className='flex justify-between items-center'>
                                    <button onClick={() => handleLoadTask(item)} className='focus:p-1 focus:font-bold  focus:bg-gray-100 mr-2 text-start flex-1'>{item.node?.objective}</button>
                                    <img alt='img' className='w-4 h-4 rotate-45 cursor-pointer' title='remove' onClick={() => handleRemoveRoutine(item._id)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};



export { RoutinesAndChallenges }